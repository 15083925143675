.p-2 {
  padding: 0.5rem 0.5rem !important;
}

#searchDureeMin,
#searchDureeMax {
  width: 60px;
  display: inline-block;
}

.align-bottom {
  vertical-align: bottom !important;
}

.form-check-label {
  color: #636c72;
  cursor: not-allowed;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: middle;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

#divChoices {
  padding-left: 10px;
  padding-top: 5px;
}
