.rf-top-nav {
    background-color: #f5f5f5;
    margin-left: -10px !important;
    margin-right: -10px !important;
    border-bottom: 2px solid rgb(116, 147, 201);
    padding-left: 10px;
}

/* Permet de "coller" un élément au haut de la fenête, sous la barre de menu */
.rf-sticky-top-bellow-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    z-index: 1030;
}

.rf-sticky-top-under {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.hidden-lg-up {
    display: none !important
}

.navbar-brand {
    display: inline-block;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none
}

.rf-top-nav span {
    cursor: pointer;
}

.nav-link {
    color: #2A2A2A !important;
}

.navbar-toggler {
    border-color: rgb(75, 107, 162) !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(75, 107, 162)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-home {
    margin-bottom: 8px;
}