@font-face {
  font-family: OpenSans;
  src: url("/src/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: OpenSans;
  src: url("/src/assets/fonts/Open_Sans/OpenSans-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: Oswald;
  src: url("/src/assets/fonts/Oswald/Oswald-ExtraLight.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Oswald;
  src: url("/src/assets/fonts/Oswald/Oswald-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Oswald;
  src: url("/src/assets/fonts/Oswald/Oswald-Regular.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Oswald;
  src: url("/src/assets/fonts/Oswald/Oswald-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Oswald;
  src: url("/src/assets/fonts/Oswald/Oswald-Bold.ttf");
  font-weight: 900;
}

/*
 Overrides globaux pour Bootstrap
*/

* {
  font-family: OpenSans;
  font-size: 13px;
}

.font-weight-bold {
  font-weight: bold;
}

button,
select,
ul,
li,
textarea,
input[type="text"],
input[type="number"],
input[type="checkbox"],
.custom-checkbox > .custom-control-indicator {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.form-control {
  background-color: white;
  border: solid 1px rgb(180, 180, 180);
  border-bottom: solid 1px rgb(150, 150, 150);
  -moz-box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05),
    inset 0px 0px 5px rgba(0, 0, 0, 0.01);
  -webkit-box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05),
    inset 0px 0px 5px rgba(0, 0, 0, 0.01);
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.05),
    inset 0px 0px 5px rgba(0, 0, 0, 0.01);
}

.btn-circle {
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  border-radius: 100% !important;
}

.page-item.active .page-link,
.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #688bc6 !important;
  border-color: #4b6ba2 !important;
}

.btn,
.page-item.active .page-link,
.custom-control-input:checked ~ .custom-control-indicator {
  cursor: pointer !important;
}

.btn-primary {
  color: #fff;
  background-color: #688bc6 !important;
  border-color: #4b6ba2 !important;
}

.btn-primary:hover {
  background-color: #01549b !important;
  border-color: #01549b !important;
}

.btn-primary:focus,
.btn-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
  box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #919191;
  background-color: #dadada !important;
  border-color: #919191 !important;
  cursor: default !important;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #688bc6 !important;
  background-image: none;
  border-color: #4b6ba2 !important;
}

.btn-outline-primary {
  color: #688bc6 !important;
  background-image: none;
  background-color: transparent;
  border-color: #4b6ba2 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #688bc6 !important;
  border-color: #4b6ba2 !important;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
  box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0275d8 !important;
  background-color: transparent !important;
  cursor: default !important;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #0275d8 !important;
  border-color: #0275d8 !important;
}

.btn-danger {
  color: #fff;
  background-color: rgb(238, 97, 97) !important;
  border-color: rgb(164, 39, 39) !important;
}

.btn-danger:hover {
  background-color: rgb(164, 39, 39) !important;
}

.btn-danger:focus,
.btn-danger.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
  box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #919191;
  background-color: #dadada !important;
  border-color: #919191 !important;
  cursor: default !important;
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background-color: rgb(238, 97, 97) !important;
  background-image: none;
  border-color: rgb(164, 39, 39) !important;
}

h3,
h4 {
  font-family: Oswald !important;
  font-weight: 100 !important;
}

/*
----------------------------------------------------------------
TinyMCE
*/
div.mce-edit-area {
  border-width: 0px !important;
  background: #fff !important;
  filter: none !important;
  padding: 10px !important;
}

/* 
----------------------------------------------------------------
Le reste
*/
body {
  background-color: #dfdfdf;
  background-image: url(pictures/fond.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
}

/* Permet de "coller" un �l�ment au haut de la fen�te, sous la barre de menu */
.rf-sticky-top-bellow-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 1030;
}

.rf-sticky-top-under {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/* Indicateurs de champs invalides */
.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid #42a948;
}

.ng-invalid:not(form) {
  border-left: 5px solid #a94341 !important;
  background-color: #efcece !important;
}

domain-value-select.ng-invalid {
  border-left: none !important;
  background-color: none;
}

domain-value-select.ng-invalid > div > span > select {
  border-left: 5px solid #a94341 !important;
  background-color: #efcece !important;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.visible-print-block {
  display: none !important;
}

@media print {
  /* Pour tenter de corriger un problème d'impression des en-têtes */
  ::first-letter {
    margin: 0 !important;
    padding: 0 !important;
  }

  .visible-print-block {
    display: block !important;
  }

  .visible-print-inline {
    display: none !important;
  }

  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }

  *,
  :after,
  :before,
  blockquote:first-letter,
  blockquote:first-line,
  div:first-letter,
  div:first-line,
  li:first-letter,
  li:first-line,
  p:first-letter,
  p:first-line {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  .navbar {
    display: none;
  }

  li {
    margin: 0 0 5px 0;
  }
}

.d-inline-block {
  display: inline-block !important;
}
