.rf-axe-fonction {
  font-family: Oswald !important;
  font-weight: 500 !important;
  font-size: 1.2em;
}

.rf-fiche-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.rf-label {
  font-weight: normal;
  font-size: smaller;
}

.rf-label:after {
  content: "\a";
  white-space: pre;
}

.rf-label.inline:after {
  content: " : ";
  white-space: inherit;
}

.rf-value {
  font-weight: 500;
}

.rf-row {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.rf-tool-bar {
  background-color: rgba(240, 240, 240, 0.9);
  padding: 4px;
  float: right;
}

.display-3,
.display-4 {
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

h3,
h4 {
  font-family: Oswald !important;
  font-weight: 100 !important;
}

.rf-logo-container {
    display: inline-block;
    gap: 20px;
    width: 35% !important;
  }

.rf-logo {
  height: 65px;
  width: auto;
  margin: 10px;
}

.rf-title {
  width: 65%;
  display: inline-block;
}