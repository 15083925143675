.pl-4 {
    padding-left: 1.5rem !important;
}

.card {
    width: 100%;
    text-align: center;
    padding: 30px;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Espace entre les logos */
  }
  
  .logo {
    height: 80px;
    width: auto;
  }
  