.rf-label {
  font-weight: normal;
  font-size: smaller;
}

.rf-label:after {
  content: "\a";
  white-space: pre;
}

.rf-label.pointer {
  cursor: pointer;
}

.rf-pagination a {
  cursor: pointer;
}

.rf-fiche-search-result {
  background-color: #fff;
}

.search-results {
  padding-left: 15px;
  padding-top: 5px;
}

.rf-resultats {
  background-color: #ffffff;
}

.rf-liste-resultats > a {
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  border-left: 4px solid #4b6ba2;
  background-color: #f6f6f6;
  border-radius: 0px;
  -moz-box-shadow: 0px 1px 1px rgba(108, 108, 108, 0.7);
  -webkit-box-shadow: 0px 1px 1px rgba(108, 108, 108, 0.7);
  box-shadow: 0px 1px 1px rgba(108, 108, 108, 0.7);
}

.rf-liste-resultats > a:hover {
  border-left: 4px solid #d69924;
}

.rf-fiche-search-count-results {
  font-size: smaller;
}

.rf-fiche-search-count-results:before {
  content: "\a";
}

.rf-liste-codes-region,
.rf-liste-values {
  display: inline;
  padding: 0px;
  margin: 0px;
}

.rf-liste-codes-region li,
.rf-liste-values li {
  display: inline;
}

.rf-liste-codes-region li:last-child:after,
.rf-liste-values li:last-child:after {
  content: none;
}

.rf-liste-codes-region li:after {
  content: " \00b7 ";
}

.rf-liste-values li:after {
  content: ", ";
}

/* ----- Critères de recherche ----- */

.rf-search-filter {
  background-color: #f2f2f2;
  padding-top: 10px;
  border-right: 1px solid #a8bcde;
}

.rf-criteria-group {
  background-color: rgb(250, 250, 250);
  margin-bottom: 4px;
  border: solid 1px #9f9f9f;
  -moz-box-shadow: 0px 1px 1px rgba(108, 108, 108, 0.7);
  -webkit-box-shadow: 0px 1px 1px rgba(108, 108, 108, 0.7);
  box-shadow: 0px 1px 1px rgba(108, 108, 108, 0.7);
}

.rf-criteria-group > .rf-label {
  background-color: #9d9d9d;
  color: white;
  padding: 5px;
}

.rf-criteria-group > .rf-collapse {
  background-color: transparent;
}

.rf-criteria-group > .rf-collapse > .rf-padded {
  margin: 5px;
}

.rf-search-option {
  background-color: #f2f2f2;
  padding: 5px;
  border-bottom: 1px dashed #7188af;
}

.rf-search-option label {
  margin: 0px !important;
}

.rf-search-option custom-control-description {
  font-size: 0.9rem;
}

/* Custom CSS */

.list-group-item-action {
  color: #495057;
  text-align: inherit;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  flex-wrap: wrap;
  align-items: center;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #292b2c;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus,
.page-link:hover {
  color: #014c8c;
  text-decoration: none;
  background-color: #eceeef;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.p-2 {
  padding: 0.5rem 0.5rem !important;
}

.list-items {
  padding-left: 15px;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
