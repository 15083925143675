.rf-app-content {
    background-color: rgb(240,240,240);
    min-height: 400px;
}

.rf-alerts {
    position: fixed;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
    bottom: 0;
    width: 100%;
    height: auto;
}


